<template>
  <transition name="fade">
    <div v-if="loading" class="fixed z-10 inset-0 flex items-center justify-center">
      <!-- backdrop -->
      <div class="absolute inset-0 bg-grey-400 opacity-75" />

      <div class="z-10 flex flex-col items-center">
        <el-loader loader-class="w-32 h-32" />

        <p class="text-white text-base mt-1">Loading</p>
      </div>
    </div>
  </transition>
</template>

<script>
const ElLoader = () => import('@/components/shared/ELLoader')

export default {
  name: 'ElLoading',

  components: { ElLoader },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
